
import React, { useState } from 'react';
import ClickForm from "../../ClickCollect/ClickForm.jsx"

export default function DemandePersonaliseSection(){

    const [showContact, setShowContact] = useState(false);

  function displayContact() {
    setShowContact(true);
  }


    return <div className="section-demande-perso">
        {showContact ? (
            <div className="section-contact-service">
        <ClickForm/>
        </div>
      ) : (
        <button onClick={displayContact} className="button-demande-perso">
          <p className="text-demande-perso">Click & Collect !</p>
        </button>
      )}
    </div>
}