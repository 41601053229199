import '../../css/Path.css';

export default function Path({ lastPage, lastPagePath, page, pagePath }) {
    // state

    // comportements

    // affichage (render)
    return (
        <ul className="list-path">
            <li>
                <p>
                    <span>
                        <a href={lastPagePath}> {lastPage} </a>
                    </span>
                </p>
            </li>
            <li>
                <p>
                <i>→</i>
                    <span>
                        <a href={pagePath}>
                            {page}
                        </a>
                    </span>
                </p>
            </li>
        </ul>
    );
}
