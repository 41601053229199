
import SearchBar from "./SearchBar";
import {useState} from "react";
import { location, vegetalFamily } from "../../db/FiltersTypes";
import ButtonFilter from "./ButtonFilter";
import srcLogo1 from "../../images/Logo/logo_interexter.png"
import srcLogo2 from "../../images/Logo/logo_type_de_plante.png"

export default function FilterBar(props) {

    

    const [inputText, setInputText] = useState("");
    function getTextFieldInput(childData) {
    setInputText(childData)
    props.InputText(inputText)
    }
    const [filter1, setFilter1] = useState("");
    function getFilter1(childData) {
        setFilter1(childData)
        props.Filter1(filter1)
    }
    const [filter2, setFilter2] = useState("");
    function getFilter2(childData) {
        setFilter2(childData)
        props.Filter2(filter2)
    }
    const [onClickFilter, setonClickFilter] = useState("");

    props.onClickFilter(onClickFilter)

    return (
        <div className="filter-bar">
            <form className="filter-bar-form">

                <SearchBar className="search-bar" handleCallBack={getTextFieldInput}/>
                <svg className="vertical-line" viewBox="0 0 3 15" xmlns="http://www.w3.org/2000/svg">
                    <line x1="2" y1="0" x2="2" y2="15" stroke="#52414C" />
                </svg>

                <ButtonFilter logo={srcLogo2} content={vegetalFamily} handleCallBack={getFilter1}/>
                <svg className="vertical-line" viewBox="0 0 3 15" xmlns="http://www.w3.org/2000/svg">
                    <line x1="2" y1="0" x2="2" y2="15" stroke="#52414C" />
                </svg>
                <ButtonFilter logo={srcLogo1} content={location} handleCallBack={getFilter2}/>
                
            </form>
        </div>

    );
}