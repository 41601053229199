import { NavItems } from "../../db/NavItems";
import { Link } from "react-router-dom";
import { useState } from "react";
import DropdownNavBar from "./DropdownNavBar";
import '../../css/Navbar.css';
import Logo from  "../../images/SVG/Logo_FleursDePalmiersTEXT.svg";


export default function NavBar() {
  const [dropdown, setDropdown] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  return <nav className="nav">

<div className={`navbar-menu ${mobileMenuOpen ? 'active' : 'inactive'}`}>
    <ul className="nav-items">
      {NavItems.map((item) => {
        if (item.title === "Services") {
          return (
            <li
              key={item.id}
              className={dropdown ? 'dropdown-menu-clicked' : 'dropdown-menu'}
              onMouseEnter={() => setDropdown(!dropdown)}
              onMouseLeave={() => setDropdown(!dropdown)}
              onClick={() => setDropdown(!dropdown)}
            >
              <a  className="link-nav-bar">{item.title}</a>
              {dropdown && <DropdownNavBar  fallback={toggleMobileMenu}/>}
            </li>
          );
        }
        else if (item.title === "Accueil") {
          return (
            <li
              key={item.id}
              className={item.cName}
            >
              <a href="/" className="link-nav-bar desktop-format-accueil">
                <img className="logo-nav-bar" src={Logo} alt="logo"/>
                </a> 
                <a onClick={toggleMobileMenu} href="/" className="link-nav-bar mobile-format-accueil">{item.title}</a> 
            </li>
          );
        }
        return (

          <li key={item.id} className={item.cName}>
            <Link  onClick={toggleMobileMenu} className="link-nav-bar" to={item.path}>{item.title}</Link>
          </li>
        );
      })}
    </ul>
    </div>
    <button className= {`navbar-burger ${mobileMenuOpen ? 'active' : 'inactive'}`}  onClick={toggleMobileMenu}>
        ☰
      </button>
      <div className="infos-pratiques-navbar"> <p>Téléphone : 06 77 91 72 58 </p><p> Mail : fleurdepalmier50@gmail.com </p></div>
  </nav>
}




