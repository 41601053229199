export default function StoryCardRight({ titleText, onClick, cardText, imgLink }) {
    // state

    // comportements

    // affichage (render)
    return (
        <section>
            <div className="storytelling-card">
                <div className="storytelling-info-right">
                    <div className="storytelling-img-container">
                        <img src={imgLink} className="storytelling-img" alt="activité"/>
                    </div>
                    <div className="storytelling-titled-text">
                        <span>
                            { titleText }
                        </span>
                        <p>
                            { cardText }
                        </p>
                        <a href={onClick}>
                            <button>
                                Voir Plus
                            </button>
                        </a>
                    </div>
                </div>
                <div className="storytelling-info-right">
                    <div className="storytelling-titled-text">
                        <span>
                            { titleText }
                        </span>
                        <div className="storytelling-img-container">
                            <img src={imgLink} className="storytelling-img" alt="activité"/>
                        </div>
                        <a href={onClick}>
                            <button>
                                Voir Plus
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}
