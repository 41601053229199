import {useState} from "react";

export default function TextBox( props ) {
    // state
    const [inputText, setInputText] = useState("");
    props.handleCallBack(inputText);
    // comportements
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
        };
    // affichage (render)
    return (
        <p className="contact-box-component">
            <label className="contact-textbox-label"> {props.title} </label>
            <input onChange={inputHandler} className="contact-textbox" type="text"/>
        </p>
    );
}
