import svg1 from '../../images/SVG/greenLines.svg';
import svg2 from '../../images/SVG/purpleLines.svg';
import svg3 from '../../images/SVG/purpleBubble.svg';
import svg4 from '../../images/SVG/pinkLines.svg';
import svg5 from '../../images/SVG/pinkBubble.svg';
import svg6 from '../../images/SVG/greenShape.svg';
import React, { useState } from "react";



export default function SectionMap() {

const [buttonSelector, setButtonSelector] = useState(false);


    return (
        <section className="section-map">
            <h2 className="titre-map">Un petit plan pour nous rejoindre !</h2>
            {buttonSelector ? <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10550.944685693908!2d-0.8822367223381227!3d48.61489317688379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480964c0bac5a167%3A0x52a354b5f3940094!2sChenilly%2C%2050140%20Mortain-Bocage!5e0!3m2!1sen!2sfr!4v1690872165430!5m2!1sen!2sfr&hl=fr"  title="Maps" allowfullscreen="" aria-hidden="false" tabindex="0" loading='lazy' referrerpolicy="no-referrer-when-downgrade"></iframe>
            :<iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21090.92722292717!2d-0.975182949635901!3d48.64111498212165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4809614a0074502b%3A0xc55476486b80a462!2s13%20Rue%20de%20L%E2%80%99%C3%89glise%2C%2050140%20Romagny-Fontenay!5e0!3m2!1sen!2sfr!4v1690873429718!5m2!1sen!2sfr&hl=fr" title="Maps" allowfullscreen="" aria-hidden="false" tabindex="0" loading='lazy' referrerpolicy="no-referrer-when-downgrade"></iframe>
            }
            <div className='container-map'>
                <button className={buttonSelector? 'button-map': 'button-map-clicked'} onClick={()=> {setButtonSelector(false)}}>Parcelle Fleurie</button>
                <button className={buttonSelector? 'button-map-clicked': 'button-map'}onClick={()=> {setButtonSelector(true)}}>Palmeraie</button>
            </div>
            
            <img className='decoration-map1' src={svg1} alt="decoration"/>
            <img className='decoration-map6' src={svg6} alt="decoration"/>
            <img className='decoration-map2' src={svg2} alt="decoration"/>
            <img className='decoration-map3' src={svg3} alt="decoration"/>
            <img className='decoration-map4' src={svg4} alt="decoration"/>
            <img className='decoration-map5' src={svg5} alt="decoration"/>
        </section>
    )
}