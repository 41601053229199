import '../../css/Copyright.css';

export default function Copyright() {
    // state

    // comportements

    // affichage (render)
    return (
        <section className="copyright">
            <span> Copyright © 2023 Sykey  | </span>
            <a href="/MentionsLegalesPage"> Mentions légales </a>
        </section>
    );
}
